/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useSwipeable } from "react-swipeable";
import {
  Star,
  StarOff,
  Keyboard,
  ArrowLeftRight,
  ArrowDown,
  Table,
  X,
  Calculator,
  Share2,
  PlusCircle,
  Menu,
} from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { evaluate } from "mathjs";
import axios from "axios";
import { useLongPress } from "use-long-press";
import { formatDistanceToNow } from "date-fns";

const API_KEY = "eadb38b720594d07c2cc5a22";
const TOP_CURRENCIES = [
  "USD",
  "EUR",
  "JPY",
  "GBP",
  "MYR",
  "SGD",
  "KRW",
  "THB",
  "VND",
  "AUD",
  "CAD",
  "DKK",
];

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const CurrencyConverter = () => {
  const [currencies, setCurrencies] = useState([]);
  const [conversionRates, setConversionRates] = useState({});
  const [fromCurrency, setFromCurrency] = useState("USD");
  const [toCurrency, setToCurrency] = useState("EUR");
  const [fromCurrencyName, setFromCurrencyName] = useState(
    "United States Dollar"
  );
  const [toCurrencyName, setToCurrencyName] = useState("Euro");
  const [baseAmount, setBaseAmount] = useState(1.0);
  const [multiplier, setMultiplier] = useState(1);
  const [expandedRowIndex, setExpandedRowIndex] = useState(null);
  const [favoriteCurrencies, setFavoriteCurrencies] = useState([]);
  const [isSimpleView, setIsSimpleView] = useState(false);
  const [simpleAmount, setSimpleAmount] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);

  const [showCalculator, setShowCalculator] = useState(false);
  const [calculatorValue, setCalculatorValue] = useState("");
  const [showAddToHomeModal, setShowAddToHomeModal] = useState(false);
  const [showFairPriceModal, setShowFairPriceModal] = useState(false);
  const [itemToBuy, setItemToBuy] = useState("");
  const [fairPriceResponse, setFairPriceResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);

  // Utility functions for cookies
  const setCookie = useCallback((name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `; expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}${expires}; path=/`;
  }, []);

  const getCookie = useCallback((name) => {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");
    for (let c of ca) {
      c = c.trim();
      if (c.startsWith(nameEQ)) return c.substring(nameEQ.length);
    }
    return null;
  }, []);

  useEffect(() => {
    // Load favorite currencies from localStorage
    const savedFavorites = localStorage.getItem("favoriteCurrencies");
    if (savedFavorites) {
      setFavoriteCurrencies(JSON.parse(savedFavorites));
    }
  }, []);

  const toggleFavorite = useCallback((currencyId) => {
    setFavoriteCurrencies((prevFavorites) => {
      const newFavorites = prevFavorites.includes(currencyId)
        ? prevFavorites.filter((id) => id !== currencyId)
        : [...prevFavorites, currencyId];

      // Save to localStorage
      localStorage.setItem("favoriteCurrencies", JSON.stringify(newFavorites));
      return newFavorites;
    });
  }, []);

  const fetchUserCurrency = useCallback(
    async (currencies) => {
      const savedFromCurrency = getCookie("fromCurrency");
      const savedToCurrency = getCookie("toCurrency");

      if (!savedFromCurrency && !savedToCurrency) {
        try {
          const formattedCurrencies = currencies.map(
            ({ id, currencyName }) => ({
              id,
              currencyName,
            })
          );
          const response = await fetch("https://ipapi.co/json/");
          const data = await response.json();
          const userCurrency = data.currency;
          setToCurrency(userCurrency);
          const currencyName =
            formattedCurrencies.find((currency) => currency.id === userCurrency)
              ?.currencyName || "";
          setToCurrencyName(currencyName);
        } catch (error) {
          console.error("Error fetching user currency:", error);
        }
      }
    },
    [getCookie]
  );

  const fetchCurrencies = useCallback(async () => {
    try {
      const cachedData = localStorage.getItem("currenciesData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (Date.now() - parsedData.timestamp < CACHE_DURATION) {
          setCurrencies(parsedData.currencies);
          return;
        }
      }

      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/${API_KEY}/codes`
      );
      const data = await response.json();
      const formattedCurrencies = data.supported_codes.map(
        ([id, currencyName]) => ({ id, currencyName })
      );

      const topCurrenciesList = formattedCurrencies.filter((currency) =>
        TOP_CURRENCIES.includes(currency.id)
      );
      const otherCurrenciesList = formattedCurrencies.filter(
        (currency) => !TOP_CURRENCIES.includes(currency.id)
      );

      const sortedCurrencies = [...topCurrenciesList, ...otherCurrenciesList];
      setCurrencies(sortedCurrencies);

      // Cache the data with a timestamp
      localStorage.setItem(
        "currenciesData",
        JSON.stringify({
          currencies: sortedCurrencies,
          timestamp: Date.now(),
        })
      );

      const savedToCurrency = getCookie("toCurrency");
      if (!savedToCurrency) fetchUserCurrency(sortedCurrencies);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  }, [getCookie, fetchUserCurrency]);

  const fetchExchangeRates = useCallback(async (currency) => {
    try {
      const cachedRates = localStorage.getItem(`exchangeRates_${currency}`);
      if (cachedRates) {
        const parsedRates = JSON.parse(cachedRates);
        if (Date.now() - parsedRates.timestamp < CACHE_DURATION) {
          setConversionRates(parsedRates.rates);
          setLastUpdated(new Date(parsedRates.timestamp));
          return;
        }
      }

      const response = await fetch(
        `https://v6.exchangerate-api.com/v6/${API_KEY}/latest/${currency}`
      );
      const data = await response.json();
      setConversionRates(data.conversion_rates);
      const currentTimestamp = Date.now();
      setLastUpdated(new Date(currentTimestamp));
      localStorage.setItem(
        `exchangeRates_${currency}`,
        JSON.stringify({
          rates: data.conversion_rates,
          timestamp: currentTimestamp,
        })
      );
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  }, []);

  useEffect(() => {
    const savedFromCurrency = getCookie("fromCurrency");
    const savedToCurrency = getCookie("toCurrency");
    const savedFromCurrencyName = getCookie("fromCurrencyName");
    const savedToCurrencyName = getCookie("toCurrencyName");

    if (savedFromCurrency) {
      setFromCurrency(savedFromCurrency);
      if (savedFromCurrencyName) setFromCurrencyName(savedFromCurrencyName);
    }
    if (savedToCurrency) {
      setToCurrency(savedToCurrency);
      if (savedToCurrencyName) setToCurrencyName(savedToCurrencyName);
    }

    fetchCurrencies();
    fetchExchangeRates(savedFromCurrency || fromCurrency);
  }, [getCookie, fetchCurrencies, fetchExchangeRates]);

  const sortedCurrencies = useMemo(() => {
    const favoriteSet = new Set(favoriteCurrencies);
    return [...currencies].sort((a, b) => {
      if (favoriteSet.has(a.id) && !favoriteSet.has(b.id)) return -1;
      if (!favoriteSet.has(a.id) && favoriteSet.has(b.id)) return 1;
      return 0;
    });
  }, [currencies, favoriteCurrencies]);

  const updateFromCurrency = useCallback(
    (value) => {
      setFromCurrency(value);
      setCookie("fromCurrency", value, 30);
      resetBaseAmount(value);
      fetchExchangeRates(value);
    },
    [setCookie, fetchExchangeRates]
  );

  const updateToCurrency = useCallback(
    (value) => {
      setToCurrency(value);
      setCookie("toCurrency", value, 30);
    },
    [setCookie]
  );

  const updateFromCurrencyName = useCallback(
    (value) => {
      setFromCurrencyName(value);
      setCookie("fromCurrencyName", value, 30);
    },
    [setCookie]
  );

  const updateToCurrencyName = useCallback(
    (value) => {
      setToCurrencyName(value);
      setCookie("toCurrencyName", value, 30);
    },
    [setCookie]
  );

  const resetBaseAmount = useCallback((currencyId) => {
    setBaseAmount(getSmallestAmount(currencyId));
    setMultiplier(1);
  }, []);

  const getSmallestAmount = useCallback((currency) => {
    switch (currency) {
      case "JPY":
      case "AMD":
        return 100;
      case "KRW":
      case "VND":
        return 1000;
      case "BHD":
      case "KWD":
      case "OMR":
        return 0.1;
      case "THB":
        return 10;
      default:
        return 1;
    }
  }, []);

  const formatCurrency = useCallback((amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }, []);

  const convertCurrency = useCallback(
    (amount) => {
      const rate = conversionRates[toCurrency] || 1;
      return amount * rate;
    },
    [conversionRates, toCurrency]
  );

  const rows = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => ({
        amount: baseAmount * (i + 1) * multiplier,
      })),
    [baseAmount, multiplier]
  );

  const increaseMultiplier = useCallback(() => {
    setBaseAmount((prevBaseAmount) => {
      if (prevBaseAmount < 1) {
        return prevBaseAmount * 10;
      } else {
        setMultiplier((prevMultiplier) => {
          const newMultiplier = prevMultiplier * 10;
          return newMultiplier <= 1000000000 ? newMultiplier : prevMultiplier;
        });
        return prevBaseAmount;
      }
    });
  }, []);

  const decreaseMultiplier = useCallback(() => {
    setBaseAmount((prevBaseAmount) => {
      if (prevBaseAmount > 1) {
        return prevBaseAmount / 10;
      } else {
        setMultiplier((prevMultiplier) =>
          prevMultiplier > 1 ? prevMultiplier / 10 : prevMultiplier
        );
        return prevBaseAmount;
      }
    });
  }, []);

  const swapCurrencies = useCallback(() => {
    setFromCurrency((prevFromCurrency) => {
      setCookie("fromCurrency", toCurrency, 30);
      setToCurrency(prevFromCurrency);
      setCookie("toCurrency", prevFromCurrency, 30);
      return toCurrency;
    });
    setFromCurrencyName((prevFromCurrencyName) => {
      setCookie("fromCurrencyName", toCurrencyName, 30);
      setToCurrencyName(prevFromCurrencyName);
      setCookie("toCurrencyName", prevFromCurrencyName, 30);
      return toCurrencyName;
    });
    fetchExchangeRates(toCurrency);
  }, [toCurrency, toCurrencyName, fetchExchangeRates, setCookie]);

  const handleSwapCurrencies = () => {
    setIsSpinning(true);
    swapCurrencies();
    setTimeout(() => setIsSpinning(false), 300); // Reset after animation
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: increaseMultiplier,
    onSwipedRight: decreaseMultiplier,
    preventDefaultTouchmoveEvent: false,
    trackMouse: false,
  });

  const toggleView = useCallback(() => {
    setIsSimpleView((prev) => !prev);
  }, []);

  const handleSimpleAmountChange = useCallback((e) => {
    const value = e.target.value;
    setSimpleAmount(value === "" ? "" : value);
  }, []);

  const handleCalculatorInput = (value) => {
    setCalculatorValue((prev) => {
      if (value === "=") {
        try {
          const result = evaluate(prev);
          setSimpleAmount(result.toString());
          setShowCalculator(false);
          return "";
        } catch (error) {
          console.error("Invalid calculation");
          return prev;
        }
      } else if (value === "C") {
        return "";
      } else {
        return prev + value;
      }
    });
  };

  const manageFairPriceRequests = useCallback(() => {
    const today = new Date().toDateString();
    const storedData = getCookie("fairPriceRequests");
    let requestData = storedData
      ? JSON.parse(storedData)
      : { date: today, count: 0 };

    if (requestData.date !== today) {
      requestData = { date: today, count: 0 };
    }

    if (requestData.count >= 5) {
      return false; // Limit reached
    }

    requestData.count += 1;
    setCookie("fairPriceRequests", JSON.stringify(requestData), 1); // Store for 1 day
    return true; // Request allowed
  }, [getCookie, setCookie]);

  const handleFairPriceSubmit = async (e) => {
    e.preventDefault();

    if (!manageFairPriceRequests()) {
      alert(
        "You've reached the daily limit of 5 FairPrice AI requests. Please try again tomorrow."
      );
      return;
    }

    setIsLoading(true);
    setFairPriceResponse(null);

    const amount = Number(simpleAmount) || 0;
    const formattedAmount = formatCurrency(amount, fromCurrency);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content:
                "You are a helpful assistant that provides information about fair pricing for items in different countries. Respond with a JSON object containing a 'fairPriceIndex' between 0 (very affordable) and 100 (very overpriced), a brief 'explanation', 'affordablePrice', and 'overpricedPrice' in the given currency. For context, a reasonably priced burger should be around 15 in MYR (Malaysian Ringgit).",
            },
            {
              role: "user",
              content: `Is ${formattedAmount} a fair price for ${itemToBuy} in ${fromCurrencyName} (${fromCurrency}), specifically in the country of the currency? Consider local economic factors and average prices. Respond with a JSON object containing a 'fairPriceIndex' between 0 (very affordable) and 100 (very overpriced), a brief 'explanation', 'affordablePrice', and 'overpricedPrice' in ${fromCurrency}. If you can't determine specific prices, omit 'affordablePrice' and 'overpricedPrice' from the response.`,
            },
          ],
          max_tokens: 200,
          temperature: 0.7,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
          },
        }
      );

      const aiResponse = JSON.parse(response.data.choices[0].message.content);
      setFairPriceResponse(aiResponse);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      setFairPriceResponse({
        fairPriceIndex: 50,
        explanation:
          "Sorry, I couldn't analyze the price at the moment. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const resetFairPriceState = useCallback(() => {
    setItemToBuy("");
    setFairPriceResponse(null);
  }, []);

  const bindLongPress = useLongPress(
    (event, { context }) => {
      setSimpleAmount(context.amount.toString());
      resetFairPriceState();
      setShowFairPriceModal(true);
    },
    {
      threshold: 500, // long press threshold in milliseconds
    }
  );

  const getRemainingRequests = useCallback(() => {
    const storedData = getCookie("fairPriceRequests");
    if (!storedData) return 5;

    const requestData = JSON.parse(storedData);
    const today = new Date().toDateString();

    if (requestData.date !== today) return 5;

    return Math.max(0, 5 - requestData.count);
  }, [getCookie]);

  const resetRequestAllowance = useCallback(() => {
    const today = new Date().toDateString();
    setCookie(
      "fairPriceRequests",
      JSON.stringify({ date: today, count: 0 }),
      1
    );
  }, [setCookie]);

  const bindLongPressReset = useLongPress(
    () => {
      resetRequestAllowance();
      alert("Daily request allowance has been reset to 5.");
    },
    {
      threshold: 1000, // 1 second long-press
      cancelOnMovement: true,
    }
  );

  const CurrencyDropdown = ({ isFrom, currentCurrency, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const searchInputRef = useRef(null);

    const handleOpenChange = (open) => {
      setIsOpen(open);
      if (!open) {
        setSearchTerm(""); // Reset search when closing dropdown
      } else {
        // Focus the search input when opening the dropdown
        setTimeout(() => searchInputRef.current?.focus(), 0);
      }
    };

    const filteredCurrencies = sortedCurrencies.filter(
      (currency) =>
        currency.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        currency.currencyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <DropdownMenu.Root open={isOpen} onOpenChange={handleOpenChange}>
        <DropdownMenu.Trigger className="w-full px-4 py-3 bg-gradient-to-r from-gray-800 to-gray-700 text-white shadow-md hover:from-gray-700 hover:to-gray-600 transition-all duration-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
          <div className="flex flex-col items-start">
            <span className="text-xs text-gray-300 mb-1">
              {isFrom ? "From" : "To"}
            </span>
            <div className="flex items-center justify-between w-full">
              <span className="text-lg font-semibold">{currentCurrency}</span>
              <ArrowDown className="w-4 h-4 text-gray-300" />
            </div>
          </div>
        </DropdownMenu.Trigger>
        <AnimatePresence>
          {isOpen && (
            <DropdownMenu.Portal forceMount>
              <DropdownMenu.Content asChild>
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className="w-full max-w-md max-h-80 overflow-y-auto bg-white shadow-lg rounded-md mt-2 border border-gray-200 z-50"
                  style={{ position: "relative", zIndex: 9999 }}
                >
                  <div className="sticky top-0 bg-white p-2 border-b border-gray-200">
                    <input
                      ref={searchInputRef}
                      type="text"
                      placeholder="Search currencies..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 text-sm"
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                  </div>
                  {filteredCurrencies.map((currency) => (
                    <DropdownMenu.Item
                      key={currency.id}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm flex justify-between items-center"
                      onSelect={(event) => {
                        event.preventDefault();
                        onSelect(currency.id, currency.currencyName);
                        setIsOpen(false);
                      }}
                    >
                      <span>
                        {currency.id} - {currency.currencyName}
                      </span>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          toggleFavorite(currency.id);
                        }}
                        className="ml-2 focus:outline-none"
                      >
                        {favoriteCurrencies.includes(currency.id) ? (
                          <Star className="w-4 h-4 text-yellow-500" />
                        ) : (
                          <StarOff className="w-4 h-4 text-gray-400" />
                        )}
                      </button>
                    </DropdownMenu.Item>
                  ))}
                </motion.div>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          )}
        </AnimatePresence>
      </DropdownMenu.Root>
    );
  };

  return (
    <div className="relative overflow-hidden bg-slate-50 font-mono">
      <div className="relative z-10 pt-4 pb-8 px-4 min-h-screen sm:px-8 text-gray-800 flex flex-col items-center  max-w-md mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="flex flex-row w-full space-x-4 mb-4 items-center"
        >
          <div className="flex flex-col items-center flex-1">
            <CurrencyDropdown
              isFrom={true}
              currentCurrency={fromCurrency}
              onSelect={(id, name) => {
                updateFromCurrency(id);
                updateFromCurrencyName(name);
              }}
            />
            <div className="text-center mt-2 text-xs text-gray-600">
              {fromCurrencyName}
            </div>
          </div>
          <motion.button
            onClick={handleSwapCurrencies}
            className="p-2 bg-gray-800 text-white rounded-full -mt-5 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 flex items-center justify-center shadow-md"
            animate={{ rotate: isSpinning ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ArrowLeftRight className="w-5 h-5" />
          </motion.button>
          <div className="flex flex-col items-center flex-1">
            <CurrencyDropdown
              isFrom={false}
              currentCurrency={toCurrency}
              onSelect={(id, name) => {
                updateToCurrency(id);
                updateToCurrencyName(name);
              }}
            />
            <div className="text-center mt-2 text-xs text-gray-600">
              {toCurrencyName}
            </div>
          </div>
        </motion.div>

        <AnimatePresence mode="wait">
          {isSimpleView ? (
            <motion.div
              key="simple-view"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="w-full max-w-md bg-white shadow-md rounded-lg p-6 mb-2"
            >
              <h2 className="text-xl font-semibold text-gray-700 mb-4 text-center">
                Quick Convert
              </h2>
              <div className="flex flex-col space-y-6">
                <motion.div
                  className="relative"
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.1 }}
                >
                  <label
                    htmlFor="fromAmount"
                    className="text-sm text-gray-600 mb-1 block"
                  >
                    Enter amount in {fromCurrency}
                  </label>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 font-semibold">
                      {fromCurrency}
                    </span>
                    <input
                      id="fromAmount"
                      type="number"
                      value={simpleAmount}
                      onChange={handleSimpleAmountChange}
                      placeholder="0.00"
                      className="w-full p-3 pl-14 pr-12 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 text-lg"
                    />
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setShowCalculator(!showCalculator)}
                      className="absolute right-3 top-1/3 transform text-gray-500 hover:text-gray-700"
                    >
                      <Calculator className="w-5 h-5" />
                    </motion.button>
                  </div>
                </motion.div>

                <AnimatePresence>
                  {showCalculator && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: "auto" }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                      className="bg-gray-100 p-4 rounded-md"
                    >
                      <input
                        type="text"
                        value={calculatorValue}
                        readOnly
                        className="w-full p-2 mb-2 text-right text-lg border border-gray-300 rounded-md"
                      />
                      <div className="grid grid-cols-4 gap-2">
                        {[
                          "7",
                          "8",
                          "9",
                          "/",
                          "4",
                          "5",
                          "6",
                          "*",
                          "1",
                          "2",
                          "3",
                          "-",
                          "0",
                          ".",
                          "=",
                          "+",
                          "C",
                        ].map((btn) => (
                          <motion.button
                            key={btn}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => handleCalculatorInput(btn)}
                            className="p-2 bg-white text-gray-800 font-bold rounded shadow hover:bg-gray-200"
                          >
                            {btn}
                          </motion.button>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>

                <motion.div
                  className="flex items-center justify-center"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{
                    delay: 0.2,
                    type: "spring",
                    stiffness: 500,
                    damping: 30,
                  }}
                >
                  <ArrowDown className="w-6 h-6 text-gray-400" />
                </motion.div>

                <motion.div
                  className="bg-gray-100 p-4 rounded-md"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <label className="text-sm text-gray-600 mb-1 block">
                    Converted amount in {toCurrency}
                  </label>
                  <motion.div
                    key={simpleAmount}
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    className="text-2xl font-bold text-gray-800"
                  >
                    {formatCurrency(
                      convertCurrency(Number(simpleAmount) || 0),
                      toCurrency
                    )}
                  </motion.div>
                  <motion.button
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5 }}
                    onClick={() => {
                      resetFairPriceState();
                      setShowFairPriceModal(true);
                    }}
                    className="mt-3 text-xs text-blue-600 hover:text-blue-800 transition-colors duration-200 focus:outline-none"
                  >
                    Wondering if it's a fair price?
                  </motion.button>
                </motion.div>
              </div>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={toggleView}
                className="w-full bg-gradient-to-r from-gray-800 to-gray-700 text-white font-semibold shadow-md hover:from-gray-700 hover:to-gray-600 transition-all duration-300 rounded-lg h-12 mt-12 flex items-center justify-center space-x-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
              >
                <Table className="w-5 h-5" />
                <span>Back to Table View</span>
              </motion.button>
            </motion.div>
          ) : (
            <motion.div
              key="table-view"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.9 }}
              transition={{ duration: 0.3 }}
              className="w-full max-w-md mb-2"
            >
              <motion.ul
                {...swipeHandlers}
                className="w-full bg-white shadow-md rounded-md"
              >
                {rows.map((row, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.05 }}
                    className={`border-b last:border-none ${
                      expandedRowIndex === index ? "bg-gray-50" : ""
                    }`}
                  >
                    <div
                      className={`flex justify-between px-4 py-4 cursor-pointer ${
                        expandedRowIndex === index ? "shadow-md" : ""
                      }`}
                      onClick={() =>
                        setExpandedRowIndex(
                          expandedRowIndex === index ? null : index
                        )
                      }
                      {...bindLongPress({ amount: row.amount })}
                    >
                      <span>{formatCurrency(row.amount, fromCurrency)}</span>
                      <div className="flex items-center">
                        <span className="font-semibold">
                          {formatCurrency(
                            convertCurrency(row.amount),
                            toCurrency
                          )}
                        </span>
                      </div>
                    </div>
                    <AnimatePresence>
                      {expandedRowIndex === index && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="bg-gray-50 border-t border-gray-200"
                        >
                          <div className="grid grid-cols-2 gap-1 px-4 py-2 text-xs font-medium text-gray-600 bg-gray-300">
                            <span className="text-left ml-2">
                              {fromCurrency}
                            </span>
                            <span className="text-right mr-2">
                              {toCurrency}
                            </span>
                          </div>
                          {Array.from({ length: 9 }, (_, i) => {
                            const subAmount =
                              row.amount +
                              (i + 1) * ((baseAmount * multiplier) / 10);
                            return (
                              <motion.div
                                key={i}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: i * 0.03 }}
                                className={`grid grid-cols-2 gap-1 px-4 py-3 text-sm border-t border-gray-100 hover:bg-gray-200 transition-colors duration-150 ${
                                  i % 2 === 0 ? "bg-gray-100" : "bg-white"
                                }`}
                                {...bindLongPress({ amount: subAmount })}
                              >
                                <span className="text-left text-gray-800 ml-2">
                                  {formatCurrency(subAmount, fromCurrency)}
                                </span>
                                <div className="text-right flex items-center justify-end mr-2">
                                  <span className="font-semibold text-gray-900">
                                    {formatCurrency(
                                      convertCurrency(subAmount),
                                      toCurrency
                                    )}
                                  </span>
                                </div>
                              </motion.div>
                            );
                          })}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.li>
                ))}
              </motion.ul>
            </motion.div>
          )}
        </AnimatePresence>

        {lastUpdated && (
          <motion.p
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="text-xs text-gray-500 text-center mb-4 mt-4"
          >
            rates last updated{" "}
            {formatDistanceToNow(lastUpdated, { addSuffix: true })}
          </motion.p>
        )}

        {!isSimpleView && (
          <motion.footer
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="fixed bottom-0 left-0 right-0 h-16 pb-4 flex justify-center space-x-4 w-full max-w-md mx-auto px-4"
          >
            <>
              <button
                onClick={decreaseMultiplier}
                className="w-1/2 bg-gray-800 text-white text-md font-bold shadow-md hover:bg-gray-700 rounded-lg"
              >
                /10
              </button>
              <button
                onClick={increaseMultiplier}
                className="w-1/2 bg-gray-800 text-white text-md font-bold shadow-md hover:bg-gray-700 rounded-lg"
              >
                x10
              </button>
            </>

            <button
              onClick={toggleView}
              className="p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700 focus:outline-none items-center align-middle h-10 mt-1"
            >
              {!isSimpleView ? (
                <Keyboard className="w-5 h-5" />
              ) : (
                <Table className="w-5 h-5" />
              )}
            </button>
          </motion.footer>
        )}

        {/* Add to Home Screen Modal */}
        <AnimatePresence>
          {showAddToHomeModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
              onClick={() => setShowAddToHomeModal(false)}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Add to Home Screen
                  </h2>
                  <button
                    onClick={() => setShowAddToHomeModal(false)}
                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
                <div className="space-y-6">
                  <div>
                    <h3 className="font-semibold text-lg mb-2 flex items-center">
                      <Share2 className="w-5 h-5 mr-2 text-blue-500" /> iOS
                      Instructions
                    </h3>
                    <ol className="list-decimal list-inside space-y-2 text-sm">
                      <li>Tap the Share button in Safari</li>
                      <li>Scroll down and tap "Add to Home Screen"</li>
                      <li>Tap "Add" in the top-right corner</li>
                    </ol>
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg mb-2 flex items-center">
                      <Menu className="w-5 h-5 mr-2 text-green-500" /> Android
                      Instructions
                    </h3>
                    <ol className="list-decimal list-inside space-y-2 text-sm">
                      <li>Tap the menu icon (3 dots) in Chrome</li>
                      <li>Tap "Add to Home screen"</li>
                      <li>Tap "Add" to confirm</li>
                    </ol>
                  </div>
                </div>
                <div className="mt-2 text-center">
                  <p className="text-sm text-gray-600 flex items-center justify-center">
                    <PlusCircle className="w-4 h-4 mr-1 text-blue-500" />
                    Enjoy quick access from your home screen!
                  </p>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* FairPrice AI Modal */}
        <AnimatePresence>
          {showFairPriceModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
              onClick={() => {
                setShowFairPriceModal(false);
                resetFairPriceState();
              }}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold text-gray-800">
                    FairPrice AI
                  </h2>
                  <button
                    onClick={() => {
                      setShowFairPriceModal(false);
                      resetFairPriceState();
                    }}
                    className="text-gray-500 hover:text-gray-700 transition-colors duration-200"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
                {!fairPriceResponse ? (
                  <form onSubmit={handleFairPriceSubmit}>
                    <label
                      htmlFor="itemToBuy"
                      className="block text-sm font-medium text-gray-700 mb-2"
                    >
                      What are you trying to buy?
                    </label>
                    <input
                      type="text"
                      id="itemToBuy"
                      value={itemToBuy}
                      onChange={(e) => setItemToBuy(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400 mb-4"
                      placeholder="e.g., a pair of sneakers"
                      required
                    />
                    <button
                      type="submit"
                      className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-200 disabled:bg-blue-300"
                      disabled={isLoading}
                      {...bindLongPressReset()}
                    >
                      {isLoading ? "Analyzing..." : "Analyze Price"}
                    </button>
                  </form>
                ) : (
                  <div>
                    <p className="text-sm font-medium text-gray-700 mb-2 mt-4">
                      Analyzing price for:
                    </p>
                    <p className="text-lg font-semibold text-gray-900 mb-4">
                      {itemToBuy}
                    </p>
                    <div className="p-3 bg-gray-100 rounded-md">
                      <p className="text-sm text-gray-800 mb-3">
                        {fairPriceResponse.explanation}
                      </p>
                      <div className="mt-2">
                        <h3 className="text-sm font-semibold text-gray-700 mb-2">
                          Affordability Scale
                        </h3>
                        <div className="flex items-center mb-1">
                          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                            <div
                              className="bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 h-2.5 rounded-full"
                              style={{
                                width: `${
                                  100 - fairPriceResponse.fairPriceIndex
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                        <div className="flex justify-between text-xs text-gray-500">
                          <span>Overpriced</span>
                          <span>Fair Price</span>
                        </div>
                        {fairPriceResponse.affordablePrice !== undefined &&
                          fairPriceResponse.overpricedPrice !== undefined &&
                          !isNaN(fairPriceResponse.affordablePrice) &&
                          !isNaN(fairPriceResponse.overpricedPrice) && (
                            <div className="flex justify-between text-xs font-medium mt-2">
                              <span>
                                {formatCurrency(
                                  fairPriceResponse.overpricedPrice,
                                  fromCurrency
                                )}
                              </span>
                              <span>
                                {formatCurrency(
                                  fairPriceResponse.affordablePrice,
                                  fromCurrency
                                )}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        setFairPriceResponse(null);
                        setItemToBuy("");
                      }}
                      className="w-full mt-4 bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-md hover:bg-gray-300 transition-colors duration-200"
                    >
                      Analyze Another Item
                    </button>
                  </div>
                )}
                <span className="text-xs text-gray-500 mt-4 block">
                  Remaining requests today: {getRemainingRequests()}
                </span>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <motion.button
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="transform text-gray-500 px-4 py-2 rounded-full shadow-lg mt-2 mb-6 flex flex-row items-center justify-center mx-auto text-xs"
        onClick={() => setShowAddToHomeModal(true)}
      >
        <PlusCircle className="w-4 h-4 mr-2" />
        Add to Home Screen
      </motion.button>

      <div className="text-center mb-24">
        <p className="text-xs text-gray-500 tracking-wide">
          made with you, in kuala lumpur
        </p>
        <p className="text-xs text-gray-400 tracking-wide">
          by{" "}
          <a
            className="text-gray-500 font-bold hover:text-gray-700 transition-colors duration-200"
            href="https://shah1d.com"
          >
            shahid rogers
          </a>
        </p>
      </div>
    </div>
  );
};

export default CurrencyConverter;
